<template>



    <div>


        <div style="max-width: 900px; margin: 0 auto; padding: 100px;">

            <div v-if="session.user.is_guest == false">

                <v-alert type="info">

                    Stai per essere reindirizzato al tuo pannello personale

                </v-alert>
                <v-progress-linear indeterminate></v-progress-linear>

            </div>

            <login_window class="ma-3"></login_window>

        </div>


    </div>

</template>


<script>


    import {mapState} from "vuex";

    import login_window from "../../components/login_window";

    export default {


        components: {


            login_window

        },

        computed: {

            ...mapState(['session'])
        },

        data: () => ({



        }),

        methods: {




        },

        mounted: function(){


            if(this.session.user.is_guest == false){

                this.$router.push({ path: 'backend' });

            }

        },

        watch: {


            session: {

                handler: function(new_value){


                    if(new_value.user.is_guest == false){


                        this.$router.push({ path: 'backend' })

                    }

                },
                deep: true
            }

        }

    }


</script>